<template>
  <b-form-group
    label="Durum Kodu"
    label-for="id_com_status"
  >
    <v-select
      id="id_com_status"
      v-model="filterData.id_com_status"
      placeholder="Durum Kodu"
      :options="statuses"
      label="title"
      :reduce="status => status.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'StatusCodes',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    statuses() {
      return this.$store.getters['statuses/getStatuses']
    },
  },
  created() {
    this.getStatuses()
  },
  methods: {
    getStatuses() {
      this.$store.dispatch('statuses/statusesList')
    },
  },
}
</script>

<style scoped>

</style>
