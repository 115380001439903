<template>
  <b-form-group
    label="Durum"
    label-for="status"
  >
    <v-select
      id="status"
      v-model="filterData.status"
      placeholder="Durum"
      :options="statuses"
      label="title"
      :reduce="status => status.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Statuses',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    defaultValue: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    statuses() {
      return [
        {
          id: '0',
          title: 'Açık',
        },
        {
          id: '1',
          title: 'Kapalı',
        },
      ]
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.filterData.status = this.defaultValue
    }
  },
}
</script>

<style scoped>

</style>
